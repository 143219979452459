import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, ListItemText, Box, Typography } from '@mui/material';
import { Option, OptionSelected } from '../types';

interface OptionNestedMenuItemProps {
    open?: boolean;
    options: Option[]; // All available options
    filters: OptionSelected[]; // Only the selected options for the filters
    setOptionFilters: (filters: OptionSelected[]) => void;
    title: string;
    type: string;
    mode: string;
    nonRemovable: boolean;
}

const OptionNestedMenuItem = ({ open, options, filters, setOptionFilters, title, type, mode, nonRemovable }: OptionNestedMenuItemProps) => {
    const handleFilter = (item) => {
        if (mode === 'multi') {
            /**
             * If the mode is multi:
             * - If it doesn't have a filter of that type, create and add it
             * - If it has the filter, find the one of that type
             *    - If the value already exists, remove it
             *    - If it doesn't exist, add it
             */
            if (filters){
                let filterExists = false;
                const newData = filters.map(currentItem => {
                    if (currentItem.type === type) {
                        filterExists = true;
                        // Check if the value is already in the values array
                        const valueIndex = currentItem.values.indexOf(item.id);
                        if (valueIndex > -1) { // Value exists in the array
                            if(!nonRemovable) {  // Remove it
                                return {
                                    ...currentItem,
                                    values: currentItem.values.filter(value => value !== item.id)
                                };
                            } else { // Can't remove it if it's the last value
                                if(currentItem.values.length > 1){
                                    return {
                                        ...currentItem,
                                        values: currentItem.values.filter(value => value !== item.id)
                                    };
                                } else {
                                    return {
                                        ...currentItem,
                                        values: currentItem.values
                                    };
                                }
                            }
                        } else {
                            return {
                                ...currentItem,
                                values: [...currentItem.values, item.id]
                            };
                        }
                    }
                    return currentItem;
                });

                if (!filterExists) {
                    newData.push({
                        type: type,
                        values: [item.id]
                    });
                }

                setOptionFilters(newData);
            }
        } else if (mode === 'single') { // DONE AND WORKING!
            /**
             * If the mode is single:
             * - If it doesn't have a filter of that type, create and add it
             * - If it has the filter, find the one of that type
             *    - If the value already exists, remove it
             *    - If it doesn't exist, add it
             */
            if (filters){
                let filterExists = false;
                const newData = filters.map(currentItem => {
                    if (currentItem.type === type) {
                        filterExists = true;
                        // Check if the value is already in the values array
                        const valueIndex = currentItem.values.indexOf(item.id);
                        if (valueIndex > -1) {  // Don't remove it if it's non-removable
                            if(!nonRemovable) { 
                                return {
                                    ...currentItem,
                                    values: []
                                };
                            } else { // Can't remove it
                                return {
                                    ...currentItem,
                                    values: currentItem.values
                                };
                            }
                        } else {
                            return {
                                ...currentItem,
                                values: [item.id]
                            };
                        }
                    }
                    return currentItem;
                });

                if (!filterExists) {
                    newData.push({
                        type: type,
                        values: [item.id]
                    });
                }

                setOptionFilters(newData);
            }
        } else {
            // TODO: grouped
        }
    }

    const renderOptions = (options: Option[]) => {
        if (!options) {
            return null;
        }

        if (mode === 'multi') {
            return options.map((item, index) => {
                // const isChecked = selectedOptions.findIndex(e => e.id === item.id) > -1;

                const foundItem = filters.find(item => item.type === type);
                const isChecked = foundItem ? foundItem.values.includes(item.id) : false;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => !item.unselectable && handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {!item.unselectable && <Checkbox
                                        checked={isChecked} />}
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(item)}>
                            <Checkbox checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return options.map((item, index) => {
                // If it's in the selectedOptions array... pass the type and check if the id exists in the values array

                const foundItem = filters.find(item => item.type === type);
                const isSelected = foundItem ? foundItem.values.includes(item.id) : false;
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => !item.unselectable && handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {!item.unselectable && <Radio
                                        checked={isSelected}
                                    />}
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderOptions(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleFilter(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.title}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            // TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderOptions(options)}
        </NestedMenuItem>
    );
};

export default OptionNestedMenuItem;
