'use strict'

import FeedbackIdeasUtils from '../feedback-ideas-utils';

import {getHierarchyAll} from '../../../../shared/services/migrated/hierarchy.service';


function FeedbackIdeasInitiativeDetailCtrl($scope, $stateParams, ErrorSvrc, FeedbackContainersService, $translate, $window, Groups, Characteristics) {
    'ngInject';

    var _this = this;

    _this.loading = true;

    _this.selectedFilters = {};

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
        hierarchies: null,
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null,
    }

    function setStatusLabels() {
        const result = FeedbackIdeasUtils.getStatusColorAndLabel(_this.initiative.status);

        _this.statusLabel = $translate.instant(result.statusLabel);
        _this.statusStyle = result.statusStyle;
    }


    _this.loadInitiative = function () {
        FeedbackContainersService.get($stateParams.initiativeId, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.initiative = result;
                setStatusLabels();
            }
            _this.loading = false;
        });

    };

    _this.arrow = {
        action: function () {
            $window.history.back();
        }
    };

    this.$onInit = function () {
        _this.loadInitiative();



        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.hierarchyFiltersConfig.hierarchies = orgdata;
            }
        });

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groupFiltersConfig.groups = groups;
        });

        Characteristics.getAll(function (err, segments) {
            if (err) {
                console.log('Error', err);
                return;
            } 
            
            _this.segmentFiltersConfig.segments = segments;
        });

    };



}


const hfFeedbackIdeasInitiativeDetail = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasInitiativeDetail', function () {
        return {
            template: require('./feedback-ideas-initiative-detail.html'),
            controller: FeedbackIdeasInitiativeDetailCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasInitiativeDetail;
