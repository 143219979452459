'use strict';

import {getHierarchyAll} from '../../../shared/services/migrated/hierarchy.service';

function FeedbackIdeasCtrl($scope, Settings, $translate, ErrorSvrc, FeedbackConfigurationService, $intercom, Groups, Characteristics) {
    'ngInject';

    var _this = this;



    _this.dateFormat = Settings.defaultDateFormat;

    _this.typeView = 1;

    _this.ideasListingFilter = {

    };

    _this.ideasInitiativesFilter = {

    };

    _this.updateFilters = function (newSelectedFilters) {
        _this.ideasListingFilter = newSelectedFilters;
        _this.ideasInitiativesFilter = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
        hierarchies: null,
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null,
    }

    _this.dateFrom = {
        title: $translate.instant('FROM'),
        nonRemovable: false,
        type: 'from',
    };

    _this.dateTo = {
        title: $translate.instant('TO'),
        nonRemovable: false,
        type: 'to'
    };

    _this.dateFiltersConfig = {
        filters: [_this.dateFrom, _this.dateTo]
    };

    $intercom.trackEvent('dashboard_visited_feedback_ideas');


    this.$onInit = function () {
        // Let's check if the ideas feedback is disabled or enabled
        FeedbackConfigurationService.checkTypeIsEnabled('SUGGESTION', function (err, enabled) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.ideasEnabled = enabled;
            }
        });

        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.hierarchyFiltersConfig.hierarchies = orgdata;
            }
        });

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groupFiltersConfig.groups = groups;
        });

        Characteristics.getAll(function (err, segments) {
            if (err) {
                console.log('Error', err);
                return;
            } 
            
            _this.segmentFiltersConfig.segments = segments;
        });
    };


}

const hfFeedbackIdeas = angular.module('happyForceApp')
    .directive('hfFeedbackIdeas', function () {
        return {
            template: require('./feedback-ideas.html'),
            controller: FeedbackIdeasCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeas;
