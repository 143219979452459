'use strict';

/* ES6 Modules Import */
import AnnouncementMoveToSectionModal
    from "./components/announcement-preview/components/announcement-move-to-section-selector/announcement-move-to-section-selector.component";
import AnnouncementCreateModal from "./components/announcement-create-modal/announcement-create-modal.component";
import AnnouncementCommentsModal from "./components/announcement-feedback/announcement-feedback.component";
import hfAnnouncementPreview from "./components/announcement-preview/announcement-preview.component";
import hfAnnouncementsListing from "./components/announcements-listing/announcements-listing.component";
import AnnounncementsSearchUtils from "./announncements_search_utils";
import hfAnnouncementsSectionsBrowser
    from "./components/announcements-sections-browser/announcements-sections-browser.component";
import hfAnnouncementListingRow
    from "./components/announcements-listing/components/announcement-listing-row/announcement-listing-row.component";
import AnnouncementReplyToCommentModal
    from "./components/announcement-reply-to-feedback-modal/announcement-reply-to-feedback-modal.component";
import hfAnnouncementEditor from "./components/announcement-editor/announcement.editor.component";
import hfAnnouncementEmulator from "./components/announcement-emulator/announcement-emulator.component";
import HFCircleDot from "../../shared/new-components/hf-circle-dot/hf-circle-dot";

import {getHierarchyAll} from "../../shared/services/migrated/hierarchy.service";

import Style from "../../utilities/style.utils";
import template from './announcements.html';

function AnnouncementsCtrl($scope, $rootScope, Announcements, AnnouncementMoveToSectionModal, AnnouncementCreateModal, AuthSrvc, Analytics, Settings, ErrorSvrc, $translate, Groups, Characteristics, $intercom, toaster) {
    'ngInject'
    var _this = this;

    // Select one of the sections

    _this.selectedSectionId = null;
    _this.selectedSection = null;
    _this.isEditing = false;
    _this.searchText = null;

    // Initialize the default filters
    _this.selectedFilters = {
        status: ['LIVE']
    };

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
        hierarchies: null,
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null,
    }

    _this.statusFilters = {
        title: $translate.instant('ANNOUNCEMENTS_SEARCH_STATUS'),
        type: 'status',
        mode: 'single',
        nonRemovable: true,
        options: [
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_LIVE'),
                id: 'LIVE'
            },
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_DRAFT'),
                id: 'DRAFT'
            },
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_ARCHIVED'),
                id: 'ARCHIVED'
            }
        ]
    };

    _this.signatureFilter = {
        title: $translate.instant('ANNOUNCEMENTS_SIGNATURE_TYPES'),
        type: 'signatureType',
        mode: 'single',
        nonRemovable: false,
        options: [
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_MANAGER_SIGNED'),
                id: 'MANAGER_SIGNED'
            },
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_HAPPYFORCE_SIGNED'),
                id: 'HAPPYFORCE_SIGNED'
            }
        ]
    };

    _this.otherFilters = {
        title: $translate.instant('OTHERS'),
        type: 'others',
        mode: 'multi',
        nonRemovable: false,
        options: [
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_HAS_REACTIONS'),
                id: 'HAS_REACTIONS'
            },
            {
                title: $translate.instant('ANNOUNCEMENTS_SEARCH_HAS_COMMENTS'),
                id: 'HAS_COMMENTS'
            }
        ]
    };

    _this.optionFiltersConfig = {
        filters: [_this.statusFilters, _this.signatureFilter, _this.otherFilters]
    };

    _this.dateFrom = {
        title: $translate.instant('FROM'),
        nonRemovable: false,
        type: 'from',
    };

    _this.dateTo = {
        title: $translate.instant('TO'),
        nonRemovable: false,
        type: 'to'
    };

    _this.dateFiltersConfig = {
        filters: [_this.dateFrom, _this.dateTo]
    };


    _this.onAnnouncementSelected = function (announcementId) {
        _this.confirmTransition(function () {
            _this.selectedAnnouncementId = announcementId;
        });
    };

    _this.onSectionSelected = function (sectionId) {
        _this.confirmTransition(function () {
            _this.selectedSectionId = sectionId;
            delete _this.selectedAnnouncementId;
            Announcements.getSection(_this.selectedSectionId, {}, function (error, sectionData) {
                if (error) {
                    ErrorSvrc.showErrorModal(error);
                } else {
                    _this.selectedSection = sectionData;
                }
            });
        });
    };

    _this.confirmTransition = function (confirmationCallback) {
        if (_this.isEditing) {
            swal({
                type: 'warning',
                title: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST'),
                text: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST_DESCRIPTION'),
                allowEscapeKey: true,
                buttons: {
                    cancel: {
                        visible: true, text: $translate.instant('ANNOUNCEMENT_CHANGES_WILL_BE_LOST_CONFIRM'),
                        value: true, className: 'swal-button--delete'
                    },
                    confirm: { text: $translate.instant('ANNOUNCEMENT_CHANGES_STAY_CANCEL'), value: false }
                },
                confirmButtonColor: Style.bad,
                closeOnConfirm: true
            }).then(function (result) {
                if (result) {
                    _this.isEditing = false;
                    confirmationCallback();
                    $scope.$digest();
                };
            });
        } else {
            _this.isEditing = false;
            confirmationCallback();
        };
    };

    _this.createAnnouncement = function () {

        AnnouncementCreateModal.showModal(function (template) {
            _this.announcementForEdit = angular.copy(template);
            _this.announcementForEdit.sectionId = _this.selectedSectionId;
            _this.announcementForEdit.commentsEnabled = true;
            _this.announcementForEdit.reactionsEnabled = true;
            _this.announcementForEdit.notify = true;
            _this.isEditing = true;
        });

    };

    _this.editAnnouncement = function (announcementId) {

        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.announcementForEdit = angular.copy(announcement);
                _this.isEditing = true;
            }
        });
    };

    _this.cloneAnnouncement = function (announcementId) {

        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.announcementForEdit = angular.copy(announcement);
                _this.announcementForEdit.id = null;
                _this.announcementForEdit.effectiveDate = null;
                _this.announcementForEdit.expirationDate = null;
                _this.announcementForEdit.repliesToComments = null;

                // Clean up the fields that are dependent on the announcement status
                delete _this.announcementForEdit.effective;
                delete _this.announcementForEdit.notified;
                delete _this.announcementForEdit.expired;

                _this.isEditing = true;
            }
        });
    };

    _this.deleteAnnouncement = function (announcementId) {
        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                swal({
                    type: 'warning',
                    title: $translate.instant('ANNOUNCEMENT_DELETE'),
                    text: $translate.instant('ANNOUNCEMENT_DELETE_MESSAGE'),
                    allowEscapeKey: true,
                    buttons: {
                        cancel: {
                            visible: true,
                            text: $translate.instant('CANCEL'),
                            value: false
                        },
                        confirm: {
                            text: $translate.instant('DELETE'),
                            value: true,
                            className: 'swal-button--delete'
                        }
                    },
                    confirmButtonColor: Style.bad,
                    closeOnConfirm: true
                }).then(function (result) {
                    if (result) {
                        Announcements.delete(announcementId, function (err, response) {
                            if (err)
                                ErrorSvrc.showErrorModal(err);
                            else {
                                _this.selectedAnnouncementId = null;
                                _this.isEditing = false;
                                _this.announcementForEdit = null;
                                $rootScope.$broadcast('announcementDeleted', { id: announcement.id, sectionId: announcement.sectionId });
                                toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_DELETED')));
                            }
                        });
                    };
                });
            }
        });
    };

    _this.changeAnnouncementSection = function (announcementId) {

        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                const currentSectionId = announcement.sectionId;
                AnnouncementMoveToSectionModal.showSectionSelector(announcementId, function (err, announcement) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        return;
                    } else {
                        $rootScope.$broadcast('announcementMoved', {
                            id: announcement.id, sectionId: announcement.sectionId,
                            previousSectionId: currentSectionId
                        });
                    }

                });
            }
        });
    };

    _this.swapAnnouncementArchivedStatus = function (announcementId) {
        Announcements.getOne(announcementId, function (err, announcement) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                const updateAnnouncementStatus = angular.copy(announcement);
                updateAnnouncementStatus.archived = !updateAnnouncementStatus.archived;
                Announcements.update(announcementId, updateAnnouncementStatus, function (err, response) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                        return;
                    } else {
                        if (response.archived)
                            toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_ARCHIVED')));
                        else
                            toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_UNARCHIVED')));

                        $rootScope.$broadcast('announcementUpdated', { id: response.id, sectionId: response.sectionId, statusChange: true });
                    }
                });

            }
        });
    };


    _this.exitEditing = function () {
        _this.isEditing = false;
        _this.announcementForEdit = null;
    };

    _this.$onInit = function () {

        // Let's load the categories
        Announcements.getCategories(function (err, categories) {
            if (err)
                ErrorSvrc.showErrorModal(err);
            else {
                _this.categoriesFilter = [

                ];
                categories.plain().forEach(function (category) {
                    _this.categoriesFilter.push({
                        id: category.id,
                        title: category.name,
                        selectable: true,
                        exclusive: false
                    });
                });
            }
        });

        Analytics.trackEvent('announcements');
        $intercom.trackEvent('dashboard_visited_announcements');

        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.hierarchyFiltersConfig.hierarchies = orgdata;
            }
        });

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groupFiltersConfig.groups = groups;
        });

        Characteristics.getAll(function (err, segments) {
            if (err) {
                console.log('Error', err);
                return;
            } 
            
            _this.segmentFiltersConfig.segments = segments;
        });


    };
}



const AnnouncementsView = function ($stateProvider) {
    'ngInject'

    // Announcements
    $stateProvider
        .state('announcements.list', {
            url: '/list',
            template: template,
            controller: AnnouncementsCtrl,
            controllerAs: '$ctrl',
            data: { pageTitle: 'SIDEMENU_ANNOUNCEMENTS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        });


    $stateProvider
        .state('announcements.reply', {
            url: '/reply/:commentId',
            template: template,
            controller: AnnouncementsCtrl,
            controllerAs: '$ctrl',
            data: { pageTitle: 'SIDEMENU_ANNOUNCEMENTS', roles: ['MANAGER', 'ADMIN', 'ADIVSOR'] }
        });
};

export default AnnouncementsView;
