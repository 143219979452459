import { useEffect, useState } from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dates from '../../../../utilities/date.utilities';
import { ListItemText, Typography } from '@mui/material';
import { DateSelected } from '../types';

interface DateNestedMenuItemProps {
    open?: boolean;
    filters: DateSelected[];
    setDateFilters?: (filters: DateSelected[]) => void;
    title: string;
    type: string;
    nonRemovable: boolean;
    //mode?: string;
}

const DateNestedMenuItem = ({ open, filters, setDateFilters, title, type, nonRemovable }: DateNestedMenuItemProps) => {
    const Settings = getService('Settings');
    const [date, setDate] = useState(null);

    //Used to load selected groups from filters
    useEffect(() => {
        if (filters && filters.length > 0) {
            filters.forEach((filter) => {
                if (filter.type === type) {
                    setDate(filter.date);
                }
            });
        }
    }, [filters]);

    useEffect(() => {
        if (date && date.isValid() && date.year() > 1900) {
            const item: DateSelected = {
                date: date,
                type: type,
                option: title,
                name: Dates.toAPIFormat(date, Settings.defaultDateFormat),
                nonRemovable: nonRemovable
            };

            let newDateFilters: DateSelected[] = [...filters];

            if (newDateFilters.findIndex(e => e.type === item.type) > -1) {
                newDateFilters = newDateFilters.filter(e => e.type !== item.type);
            }

            newDateFilters.push(item);
            setDateFilters(newDateFilters);
        }
    }, [date]);

    const renderDate = () => {
        return (
            <DatePicker
                value={date}
                onChange={setDate}
            />
        );
    }

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderDate()}
        </NestedMenuItem>
    );
};

export default DateNestedMenuItem;
