'use strict';

function DateBrowserBoxCtrl($scope, $rootScope, AuthSrvc, Settings, ErrorSvrc, CompanySvrc, $translate) {
    var _this = this;


    _this.dateGroupingTypes = [];


    CompanySvrc.getUserCompany(function (err, company) {
        if (!err) {
            $scope.minDate = moment(company.createdOn);
        }
    });

    function updateSelectorSettings() {


        if (_this.dateGrouping == 'MONTH') {
            _this.dateFormat = Settings.defaultMonthDateFormat;
            _this.minView = 'year';
        } else if (_this.dateGrouping == 'QUARTER') {
            _this.dateFormat = Settings.defaultQuarterDateFormat;
            _this.minView = 'year';
        } else if (_this.dateGrouping == 'YEAR') {
            _this.dateFormat = Settings.defaultYearDateFormat;
            _this.minView = 'year';
        }


        $rootScope.$broadcast('pickerUpdate', 'date_range_picker', {
            format:  _this.dateFormat,
            minView: _this.minView
        });
    }


    _this.prevPeriod = function () {
        _this.to = moment(_this.to).subtract(1, _this.dateGrouping).toDate();
    };

    _this.nextPeriod = function () {
        var nextDate = moment(_this.to).add(1, _this.dateGrouping).toDate();
        if (nextDate.getTime() <= Date.now()) {
            _this.to = nextDate;
        }
    };

    function transformDateToEnpsQuarter() {
        let toDate = moment(_this.to);

        if (!toDate.isValid()) {
            console.error('this.to no es un objeto moment válido');
        } else {
            const month = toDate.month();
            let adjustedMonth;

            if (month >= 11 || month <= 1) {
                adjustedMonth = 11;
            } else if (month >= 2 && month <= 4) {
                adjustedMonth = 2;
            } else if (month >= 5 && month <= 7) {
                adjustedMonth = 5;
            } else if (month >= 8 && month <= 10) {
                adjustedMonth = 8;
            }

            toDate.month(adjustedMonth);

            _this.to = toDate.toDate(); 
        }
    }


    _this.$onInit = function () {
        if(_this.enps === true){
            transformDateToEnpsQuarter();
        }

        if (!_this.showMonths && !_this.showQuarters && !_this.showYears) {
            _this.showMonths = true;
            _this.dateGrouping = 'MONTH';
        }

        if (typeof _this.showDateGrouping === 'undefined') {
            _this.showDateGrouping = true;
        }

        if (_this.showMonths)
            _this.dateGroupingTypes.push({ id: 'MONTH', label: $translate.instant('DATE_GROUPING_MONTH') });

        if (_this.showQuarters)
            _this.dateGroupingTypes.push({ id: 'QUARTER', label: $translate.instant('DATE_GROUPING_QUARTER') });

        if (_this.showYears)
            _this.dateGroupingTypes.push({ id: 'YEAR', label: $translate.instant('DATE_GROUPING_YEAR') });

        updateSelectorSettings();

        ['dateGrouping'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n != o)
                    updateSelectorSettings();
            });
        });
    };

}

const hfEngagementDateBrowser = angular.module('happyForceApp').directive('hfEngagementDateBrowser', function () {
    return {
        scope: {
            to: '=',
            dateGrouping: '=',
            showMonths: '<?',
            showQuarters: '<?',
            showYears: '<?',
            showDateGrouping: '=',
            enps: '='
        },
        restrict: 'E',
        template: require('./date_browser.html'),
        controller: DateBrowserBoxCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfEngagementDateBrowser;
