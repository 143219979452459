import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import { Menu, Box, Button, Typography } from '@mui/material';
import HierarchyNestedMenuItem from './components/HierarchyNestedMenuItem';
import GroupNestedMenuItem from './components/GroupNestedMenuItem';
import SegmentNestedMenuItem from './components/SegmentNestedMenuItem';
import OptionNestedMenuItem from './components/OptionNestedMenuItem';
import DateNestedMenuItem from './components/DateNestedMenuItem';
import HFTag from '../hf-tag/hf-tag';
import angular from 'angular';
import FilterIcon from '@mui/icons-material/FilterAlt';
import {
    HierarchyFiltersConfig, Children,
    GroupFiltersConfig,
    SegmentFiltersConfig, DateFiltersConfig,
    DateSelected, OptionFiltersConfig, Option,
    OptionSelected, PillFilter, FilterSelected,
    SegmentSelected, SegmentValue, SegmentRange
} from './types';
import Dates from '../../../utilities/date.utilities';

interface HFFilterMenuProps {
    hierarchyFiltersConfig?: HierarchyFiltersConfig;
    groupFiltersConfig?: GroupFiltersConfig;
    segmentFiltersConfig?: SegmentFiltersConfig;
    dateFiltersConfig?: DateFiltersConfig;
    optionFiltersConfig?: OptionFiltersConfig;
    tagColor?: string;
    selectedFilters: FilterSelected[];
    updateFilters: (filters: FilterSelected[]) => void;
}

const HfFilterMenu = ({ hierarchyFiltersConfig, groupFiltersConfig,
    segmentFiltersConfig, dateFiltersConfig, optionFiltersConfig,
    tagColor, selectedFilters, updateFilters }: HFFilterMenuProps) => {
    const [filters, setFilters] = useState<FilterSelected[]>(selectedFilters);
    const [tagFilters, setTagFilters] = useState<PillFilter[]>([]);
    const [hierarchyInitialized, setHierarchyInitialized] = useState(false);
    const [hierarchyFilters, setHierarchyFilters] = useState<string[]>([]);
    const [groupInitialized, setGroupInitialized] = useState(false);
    const [groupFilters, setGroupFilters] = useState<string[]>([]);
    const [segmentInitialized, setSegmentInitialized] = useState(false);
    const [segmentFilters, setSegmentFilters] = useState<SegmentSelected[]>([]);
    const [optionInitialized, setOptionInitialized] = useState(false);
    const [optionFilters, setOptionFilters] = useState<OptionSelected[]>([]);
    const [dateInitialized, setDateInitialized] = useState(false);
    const [dateFilters, setDateFilters] = useState<DateSelected[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const [initializedFilters, setInitializedFilters] = useState(false);
    const $translate = getService('$translate');
    const Languages = getService('Languages');
    const Settings = getService('Settings');
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!initializedFilters && optionFiltersConfig) {
            const newOptionFilters: OptionSelected[] = optionFilters ? optionFilters : [];
            optionFiltersConfig.filters.forEach(filter => {
                const filterType = filter.type;

                if (selectedFilters[filterType] && selectedFilters[filterType].length > 0) {
                    const filterToAdd: OptionSelected = { type: filterType, values: selectedFilters[filterType] };
                    newOptionFilters.push(filterToAdd);
                }
            });
            setOptionFilters(newOptionFilters);
        }

        if (!initializedFilters) {
            setInitializedFilters(true);
        }
    }, [initializedFilters]);

    //Used to print pills
    useEffect(() => {
        const newTags: PillFilter[] = [];

        //For hierarchy
        if (hierarchyFiltersConfig && hierarchyFiltersConfig.hierarchies) {
            hierarchyFiltersConfig.hierarchies.forEach(hierarchy => {
                if (hierarchyFilters.includes(hierarchy.id)) {
                    newTags.push({
                        nonRemovable: hierarchyFiltersConfig.nonRemovable,
                        option: hierarchyFiltersConfig.title,
                        name: hierarchy.name,
                        type: hierarchyFiltersConfig.type,
                        id: hierarchy.id
                    });
                }
                if (hierarchy.children) {
                    printChildrenPills(hierarchy.children, newTags);
                }
            });
        }

        //For groups
        if (groupFiltersConfig && groupFiltersConfig.groups) {
            groupFiltersConfig.groups.forEach(group => {
                if (groupFilters.includes(group.id)) {
                    newTags.push({
                        nonRemovable: hierarchyFiltersConfig.nonRemovable,
                        option: hierarchyFiltersConfig.title,
                        name: group.name,
                        type: hierarchyFiltersConfig.type,
                        id: group.id
                    });
                }
            });
        }

        //For segments
        if (segmentFiltersConfig && segmentFiltersConfig.segments) {
            segmentFiltersConfig.segments.forEach(segment => {
                if (segmentFilters.findIndex(e => e.id === segment.id) > -1) {
                    if (segment.questionType === 'LIST') {
                        segment.values.map((item: SegmentValue) => {
                            if (segmentFilters.find(e => e.id === segment.id).values.includes(item.id)) {
                                newTags.push({
                                    nonRemovable: segmentFiltersConfig.nonRemovable,
                                    option: segment.description,
                                    name: Languages.getTranslationFromUserLanguage(item.value),
                                    type: segmentFiltersConfig.type,
                                    id: segment.id,
                                    subId: item.id
                                });
                            }
                        });
                    }
                    if (segment.questionType === 'DATE') {
                        segment.ranges.map((item: SegmentRange) => {
                            if (segmentFilters.find(e => e.id === segment.id).values.includes(item.id)) {
                                newTags.push({
                                    nonRemovable: segmentFiltersConfig.nonRemovable,
                                    option: segment.description,
                                    name: Languages.getTranslationFromUserLanguage(item.name),
                                    type: segmentFiltersConfig.type,
                                    id: segment.id,
                                    subId: item.id
                                });
                            }
                        });
                    }
                }
            });
        }

        //For options
        if (optionFiltersConfig) {
            optionFiltersConfig.filters.forEach(filter => {
                const filterType = filter.type;

                if (selectedFilters[filterType] && selectedFilters[filterType].length > 0) { //exists a filter of this type
                    selectedFilters[filterType].forEach(value => {
                        const option = findOptionById(filter.options, value);

                        if (option) {
                            newTags.push({
                                nonRemovable: filter.nonRemovable,
                                option: filter.title,
                                name: option.title,
                                type: filterType,
                                id: option.id
                            });
                        }
                    })
                }
            });
        }

        if (dateFiltersConfig) {
            dateFiltersConfig.filters.forEach(filter => {
                const filterType = filter.type;

                if (selectedFilters[filterType]) {
                    newTags.push({
                        nonRemovable: filter.nonRemovable,
                        option: filter.title,
                        name: Dates.toAPIFormat(selectedFilters[filterType], Settings.defaultDateFormat),
                        type: filterType
                    });
                }
            });
        }

        setTagFilters(newTags);
    }, [selectedFilters]);

    useEffect(() => {
        console.log('filters', JSON.stringify(filters));
        if (filters) {
            updateFilters(filters);
        }
    }, [filters]);

    //DONE
    useEffect(() => {
        if (!hierarchyInitialized) {
            setHierarchyInitialized(true);
            return;
        }

        const newFilters = { ...filters };
        newFilters[hierarchyFiltersConfig.type] = hierarchyFilters;

        setFilters(newFilters);
    }, [hierarchyFilters]);

    //DONE
    useEffect(() => {
        if (!groupInitialized) {
            setGroupInitialized(true);
            return;
        }

        const newFilters = { ...filters };
        newFilters[groupFiltersConfig.type] = groupFilters;

        setFilters(newFilters);
    }, [groupFilters]);

    //DONE
    useEffect(() => {
        if (!segmentInitialized) {
            setSegmentInitialized(true);
            return;
        }

        const newFilters = { ...filters };
        newFilters[segmentFiltersConfig.type] = segmentFilters;

        setFilters(newFilters);
    }, [segmentFilters]);

    //DONE
    useEffect(() => {
        if (!optionInitialized) {
            setOptionInitialized(true);
            return;
        }

        const newFilters = { ...filters };

        optionFiltersConfig.filters.forEach(filter => {
            delete newFilters[filter.type];
        });

        optionFilters.forEach(item => {
            newFilters[item.type] = item.values;
        });

        setFilters(newFilters);
    }, [optionFilters]);

    //DONE
    useEffect(() => {
        if (!dateInitialized) {
            setDateInitialized(true);
            return;
        }

        const newFilters = { ...filters };

        dateFiltersConfig.filters.forEach(filter => {
            delete newFilters[filter.type];
        });

        dateFilters.forEach(item => {
            newFilters[item.type] = item.date;
        });

        setFilters(newFilters);
    }, [dateFilters]);

    const findOptionById = (options: Option[], id: string): Option | undefined => {
        for (const option of options) {
            if (option.id === id) {
                return option;  // If the ID matches, return the option.
            }

            // If there are children, perform a recursive search.
            if (option.children) {
                const found = findOptionById(option.children, id);
                if (found) {
                    return found;  // If found in children, return it.
                }
            }
        }
        return undefined;  // If nothing is found, return undefined.
    }


    const printChildrenPills = (children: Children[], newTags: PillFilter[]) => {
        children.forEach(hierarchy => {
            if (hierarchyFilters.includes(hierarchy.id)) {
                newTags.push({
                    nonRemovable: hierarchyFiltersConfig.nonRemovable,
                    option: hierarchyFiltersConfig.title,
                    name: hierarchy.name,
                    type: hierarchyFiltersConfig.type,
                    id: hierarchy.id
                });
            }
            if (hierarchy.children) {
                printChildrenPills(hierarchy.children, newTags);
            }
        });
    }

    const handleClick = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleRemove = (tag: PillFilter) => {
        //Hierarchy filter
        if (hierarchyFilters.includes(tag.id)) {
            const newFilters = hierarchyFilters.filter(filter => filter !== tag.id);
            setHierarchyFilters(newFilters);
        }

        //Group filter
        if (groupFilters.includes(tag.id)) {
            const newFilters = groupFilters.filter(filter => filter !== tag.id);
            setGroupFilters(newFilters);
        }

        //Segments filter
        if (segmentFilters.findIndex(e => e.id === tag.id) > -1) { // The filter exists. Remove the value
            setSegmentFilters(prevSegments => {
                // We map and filter based on the previous state
                const updatedSegments = prevSegments.map(segment => {
                    if (segment.id === tag.id) {
                        // Filter the values to remove the subId
                        const updatedValues = segment.values.filter(value => value !== tag.subId);

                        // If there are remaining values, update the segment
                        if (updatedValues.length > 0) {
                            return { ...segment, values: updatedValues };
                        }
                        // If no values remain, return null
                        return null;
                    }
                    return segment;
                }).filter(segment => segment !== null); // Filter out nulls (empty segments)

                return updatedSegments as SegmentSelected[]; // Update the state with the modified segments
            });
        }

        //Option filter
        if (optionFiltersConfig) {
            optionFiltersConfig.filters.forEach(filter => {
                if (filter.type === tag.type) {
                    const newFilters = optionFilters.map(filter => {
                        if (filter.type === tag.type) {
                            return {
                                ...filter,
                                values: filter.values.filter(value => value !== tag.id)
                            };
                        }
                        return filter;
                    });

                    setOptionFilters(newFilters);
                }
            });
        }

        //Date filter 
        if (dateFiltersConfig) {
            dateFiltersConfig.filters.forEach(filter => {
                if (filter.type === tag.type) {
                    const newFilters = [...dateFilters];
                    newFilters.forEach((filter, index) => {
                        if (filter.type === tag.type) {
                            newFilters.splice(index, 1);
                        }
                    });

                    setDateFilters(newFilters);
                }
            });
        }
    }

    const renderTags = () => {
        if (!tagFilters || !tagFilters.length) {
            return null;
        }

        return (
            <Box sx={(theme) => ({
                display: 'flex', alignContent: 'center', gap: theme.spacing(1),
                flexDirection: 'row', flexWrap: 'wrap', overflow: 'hidden'
            })}>
                {
                    tagFilters.map((tag, tagIndex) => {
                        if (tag.nonRemovable) {
                            return <HFTag key={tagIndex}
                                text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                status={tagColor ? tagColor : 'neutral'} />;
                        } else {
                            return <HFTag key={tagIndex}
                                text={'<strong>' + tag.option + ': </strong>' + tag.name}
                                status={tagColor ? tagColor : 'neutral'}
                                onRemove={() => handleRemove(tag)} />;
                        }
                    })

                }
            </Box>
        )
    }

    return (
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), flexDirection: 'row' })}>
            <Button
                variant="text"
                size="small"
                style={{ textTransform: 'none' }}
                onClick={handleClick}
                startIcon={<FilterIcon />}>
                <Typography variant={'caption'} sx={{ textTransform: 'capitalize' }}>
                    {$translate.instant('FILTER')}
                </Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {hierarchyFiltersConfig &&
                    <Box><HierarchyNestedMenuItem
                        open={open}
                        filters={hierarchyFilters}
                        setHierarchyFilters={setHierarchyFilters}
                        mode={hierarchyFiltersConfig.mode}
                        title={hierarchyFiltersConfig.title}
                        nonRemovable={hierarchyFiltersConfig.nonRemovable}
                        hierarchies={hierarchyFiltersConfig.hierarchies} />
                    </Box>}
                {groupFiltersConfig &&
                    <Box><GroupNestedMenuItem
                        open={open}
                        filters={groupFilters}
                        setGroupFilters={setGroupFilters}
                        mode={groupFiltersConfig.mode}
                        title={groupFiltersConfig.title}
                        nonRemovable={groupFiltersConfig.nonRemovable}
                        groups={groupFiltersConfig.groups} />
                    </Box>}
                {segmentFiltersConfig &&
                    <Box><SegmentNestedMenuItem
                        open={open}
                        segments={segmentFiltersConfig.segments}
                        filters={segmentFilters}
                        setSegmentFilters={setSegmentFilters}
                        title={segmentFiltersConfig.title}
                        mode={segmentFiltersConfig.mode}
                        nonRemovable={segmentFiltersConfig.nonRemovable} />
                    </Box>}
                {/**DONE */}
                {optionFiltersConfig &&
                    <Box>{optionFiltersConfig.filters.map((option, index) => (
                        <Box key={index}><OptionNestedMenuItem
                            open={open}
                            options={option.options}
                            filters={optionFilters}
                            setOptionFilters={setOptionFilters}
                            title={option.title}
                            type={option.type}
                            mode={option.mode}
                            nonRemovable={option.nonRemovable} /></Box>
                    ))}</Box>}
                {/**WIP */}
                {dateFiltersConfig &&
                    <Box>{dateFiltersConfig.filters.map((date, index) => (
                        <Box key={index}>
                            <DateNestedMenuItem
                                open={open}
                                filters={dateFilters}
                                setDateFilters={setDateFilters}
                                title={date.title}
                                type={date.type}
                                nonRemovable={date.nonRemovable} />
                        </Box>
                    ))}</Box>}
            </Menu>
            {renderTags()}
        </Box>
    );

};

/*HFDateTaggingFilter.propTypes = {
    mode: PropTypes.string,
    label: PropTypes.string.isRequired,
    typeLabel: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    nonRemovable: PropTypes.bool,
    maxView: PropTypes.string,
    minView: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    view: PropTypes.string,
    format: PropTypes.string,
    tagFilterMenu: PropTypes.object.isRequired
};*/

angularize(HfFilterMenu, 'hfFilterMenu', angular.module('happyForceApp'), {
    hierarchyFiltersConfig: '<',
    groupFiltersConfig: '<',
    segmentFiltersConfig: '<',
    optionFiltersConfig: '<',
    dateFiltersConfig: '<',
    selectedFilters: '<',
    updateFilters: '<',
    tagColor: '<',
});