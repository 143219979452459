



import Numbers from '../../utilities/number.utilities';

import template from './manage.users.html';

import Style from '../../utilities/style.utils';
import UserListing from 'root/app/components/users/components/user_listing';
//import { mode } from 'webpack-test.config';

import {getHierarchyAll} from '../../shared/services/migrated/hierarchy.service';

function ManageUsersCtrl($scope,
    $stateParams,
    ErrorSvrc,
    People,
    Reports,
    AuthSrvc,
    UserSendReminderModal,
    $translate,
    Groups,
    Characteristics,
    FileSaver) {
    'ngInject';
    var _this = this;

    // Initialize the default filters
    _this.selectedFilters = {
        deletedStatus: [false],
        activatedStatus: [true, false],
        employeeType: ['EMPLOYEE','ADMIN', 'MANAGER'],
        mailStatus: ['ANY']
    };

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
        hierarchies: null,
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null,
    }

    _this.deletedStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_STATUS'),
        type: 'deletedStatus',
        mode: 'single',
        nonRemovable: true,
        options: [
            {
                title: $translate.instant('INVITED'),
                id: false
            },
            {
                title: $translate.instant('DELETED'),
                id: true
            }
        ]
    };

    //TODO: preparar esto
    //Luego ver que se recibe en el optionNestedMenuItem, para ver que se hace en el render, que ahora esta vacio

    _this.activationStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_ACTIVATION_STATUS'),
        type: 'activatedStatus',
        mode: 'multi',
        nonRemovable: false,
        options: [
            {
                title: $translate.instant('EMPLOYEE_ACTIVATED'),
                id: true
            },
            {
                title: $translate.instant('EMPLOYEE_NOT_ACTIVATED'),
                id: false
            }
        ]
    };


    _this.roleFilterOptions = {
        title: $translate.instant('USER_TYPE'),
        type: 'employeeType',
        nonRemovable: false,
        mode: 'multi',
        options: [
            {
                title: $translate.instant('EMPLOYEE'),
                id: 'EMPLOYEE'
            },
            {
                title: $translate.instant('ADMIN'),
                id: 'ADMIN'
            },
            {
                title: $translate.instant('MANAGER'),
                id: 'MANAGER'
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        _this.roleFilterOptions.options.push({
            title: $translate.instant('ADVISOR'),
            id: 'ADVISOR'
        });
    }

    _this.mailStatusOptions = {
        title: $translate.instant('EMPLOYEE_MAIL_STATUS'),
        type: 'mailStatus',
        nonRemovable: true,
        mode: 'single',
        options: [
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_ANY'),
                id: 'ANY'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_CORRECT'),
                id: 'CORRECT'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_FAILING'),
                id: 'FAILING'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNSUB'),
                id: 'UNSUBSCRIBED'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNKNOWN'),
                id: 'UNKNOWN'
            }
        ]
    };

    _this.profileStatusFilterOptions = {
        title: $translate.instant('EMPLOYEE_CONFIG_STATUS'),
        type: 'profileStatus',
        nonRemovable: false,
        mode: 'multi',
        options: [
            {
                title: $translate.instant('EMPLOYEE_WITHOUT_AREA'),
                id: 'NO_AREA'
            },
            {
                title: $translate.instant('EMPLOYEE_WITHOUT_FULL_PROFILE'),
                id: 'INCOMPLETE_PROFILE'
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        _this.profileStatusFilterOptions.options.push({
            title: $translate.instant('BLOCKED_EMPLOYEES'),
            id: 'BLOCKED'
        });
    }

    _this.optionFiltersConfig = {
        filters: [_this.deletedStatusFilterOptions,
            _this.activationStatusFilterOptions,
            _this.roleFilterOptions,
            _this.mailStatusOptions,
            _this.profileStatusFilterOptions]
    };

    _this.color = function (value) {
        if (value === 0) {
            return Style.noData;
        } else if (value <= 25) {
            return Style.bad;
        } else if (value <= 50) {
            return Style.warning;
        } else if (value <= 75) {
            return Style.good;
        } else {
            return Style.excellent;
        }
    };

    _this.valuation = function (value) {
        if (value === 0) {
            return $translate.instant('EMPLOYEE_VALUATION_NO_DATA');
        } else if (value <= 25) {
            return $translate.instant('EMPLOYEE_VALUATION_BAD');
        } else if (value <= 50) {
            return $translate.instant('EMPLOYEE_VALUATION_WARNING');
        } else if (value <= 75) {
            return $translate.instant('EMPLOYEE_VALUATION_GOOD');
        } else {
            return $translate.instant('EMPLOYEE_VALUATION_EXCELENT');
        }
    };

    _this.searchText = $stateParams && $stateParams.email || null;

    _this.statuses = [
        {
            name: $translate.instant('INVITED'),
            value: 'invited'

        },
        {
            name: $translate.instant('DELETED'),
            value: 'deleted'
        }
    ];

    _this.header = {
        title: $translate.instant('EMPLOYEES_SECTION_TITLE'),
        subtitle: $translate.instant('EMPLOYEES_SECTION_DESCRIPTION')
    };


    // This is a trick to force the update of the component on the react side
    _this.reactFilters = JSON.stringify({
        searchText: _this.searchText,
        selectedFilters: _this.selectedFilters
    });


    _this.sendReminder = function (type) {
        UserSendReminderModal.showSendReminderModal(type, null, null, null, function (err) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }
        });
    };

    _this.loadUserstats = function () {

        People.stats.get(function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);

            } else {


                _this.invitedCount = results.invited;

                _this.activatedCount = results.activated;
                _this.activatedPercent = Numbers.roundNumber(Numbers.calculatePercent(results.activated, results.invited), 1);
                _this.activatedPercentValuation = _this.valuation(_this.activatedPercent);
                _this.activatedPercentColor = _this.color(_this.activatedPercent);

                _this.sleepingCount = results.sleeping;
                _this.sleepingPercent = Numbers.roundNumber(Numbers.calculatePercent(results.sleeping, results.activated), 1);
                _this.sleepingPercentValuation = _this.valuation(100 - _this.sleepingPercent);
                _this.sleepingPercentColor = _this.color(100 - _this.sleepingPercent);


            }
        });
    };


    _this.inviteUser = function () {
        _this.isInviting = true;
        $scope.$apply();
    };

    _this.handleInviteCancel = function () {
        _this.isInviting = false;
        $scope.$apply();
    }


    _this.exportEmployeesToExcel = function () {
        _this.exportingExcelReport = true;
        People.getTemplate('UPDATE', function (err, template) {
            _this.exportingExcelReport = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                var blob = new Blob([template], {});
                FileSaver.saveFile(blob, 'users.xlsx');
            }

        });
    };

    _this.exportAdvisorsEmployeesToExcel = function () {
        _this.exportingExcelReport = true;
        Reports.users(function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                swal('Fichero en camino!', '', 'success');
            }
        });
    };






    this.$onInit = function () {


        $scope.$watch(angular.bind($scope, function () {
            return _this.selectedFilters;
        }), function (newVal, oldVal) {
            if (!newVal || newVal === oldVal) { return; }

            // This is a trick to force the update of the component on the react side
            _this.reactFilters = JSON.stringify(
                {
                    searchText: _this.searchText,
                    selectedFilters: newVal
                }
            )
        }, true);


        $scope.$watch(angular.bind($scope, function () {
            return _this.searchText;
        }), function (newVal, oldVal) {

            if (!newVal || newVal === oldVal) { return; }

            // This is a trick to force the update of the component on the react side
            _this.reactFilters = JSON.stringify(
                {
                    searchText: newVal,
                    selectedFilters: _this.selectedFilters
                }
            )
        }, true);

        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.hierarchyFiltersConfig.hierarchies = orgdata;
            }
        });

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groupFiltersConfig.groups = groups;
        });

        Characteristics.getAll(function (err, segments) {
            if (err) {
                console.log('Error', err);
                return;
            } 
            
            _this.segmentFiltersConfig.segments = segments;
        });


    };

    _this.loadUserstats();

}


const ManageUsersView = function ManageUsersRoute($stateProvider) {
    'ngInject';
    $stateProvider.state('manage.users', {
        url: '/users/manage',
        controller: ManageUsersCtrl,
        controllerAs: '$ctrl',
        template: template,
        data: { pageTitle: 'MANAGE_USERS', roles: ['ADMIN', 'ADVISOR'] }
    });
}
export default ManageUsersView;
