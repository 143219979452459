'use strict';

import async from 'async';
import Dates from '../../../../utilities/date.utilities';
import Numbers from '../../../../utilities/number.utilities';
import {getHierarchyAll} from '../../../../shared/services/migrated/hierarchy.service';

function EngagementPrioritiesBoxCtrl($scope, InsightsService, SegmentsFilterService, ErrorSvrc, $translate, Groups, Characteristics) {
    'ngInject';
    var _this = this;

    // Initialize the default filters
    _this.selectedFilters = {

    };

    _this.updateFilters = function (newSelectedFilters) {
        _this.selectedFilters = newSelectedFilters;
        $scope.$apply();
    };

    // Prepare the filters
    _this.hierarchyFiltersConfig = {
        id: 'HIERARCHY',
        type: 'hierarchyIds',
        mode: 'multi',
        title: $translate.instant('AREA'),
        nonRemovable: false,
        hierarchies: null,
    }

    _this.groupFiltersConfig = {
        id: 'GROUP',
        type: 'groups',
        mode: 'multi',
        title: $translate.instant('GROUPS'),
        nonRemovable: false,
        groups: null,
    }

    _this.segmentFiltersConfig = {
        id: 'SEGMENT',
        type: 'segments',
        mode: 'multi',
        title: $translate.instant('SEGMENTED'),
        nonRemovable: false,
        segments: null,
    }

    _this.positiveInsights = [];
    _this.totalPositive = 0;

    _this.negativeInsights = [];
    _this.totalNegative = 0;
    _this.dates = {};

    function processInsight(insights, positive) {
        var results = [];

        insights.forEach(function (insight) {
            if (insight.companyBenchmark) {
                insight.difference = insight.value - insight.companyBenchmark;
            }
            insight.linkParams = {
                scoreId: insight.scoreId,
                hierarchyId: insight.hierarchyId,
                filters: insight.characteristic ? insight.characteristic.characteristicId + ':' + insight.characteristic.value : null
            };

            var params = {
                difference: Numbers.roundNumber(insight.value - insight.companyBenchmark, 1),
                companyBenchmark: Numbers.roundNumber(insight.companyBenchmark, 1),
                color: insight.positive ? '#0CAD00' : '#FE2847'
            };
            var label = null;


            if (insight.type === 'HI_IMPACT') {
                label = 'ENGAGEMENT_INSIGHTS_HI_IMPACT';

            } else if (insight.type === 'ENPS_IMPACT') {
                label = 'ENGAGEMENT_INSIGHTS_ENPS_IMPACT';

            } else if (insight.type === 'VARIATION') {
                if (positive) {
                    label = 'ENGAGEMENT_INSIGHTS_POSITIVE_VARIATION';
                } else {
                    label = 'ENGAGEMENT_INSIGHTS_NEGATIVE_VARIATION';
                }

            } else {
                if (positive) {
                    label = 'ENGAGEMENT_INSIGHTS_POSITIVE_DIFFERENCE';
                } else {
                    label = 'ENGAGEMENT_INSIGHTS_NEGATIVE_DIFFERENCE';
                }

            }

            insight.label = $translate.instant(label, params);
            results.push(insight);
        });

        return results;
    }

    function loadInsights(next, type, loadMore) {


        // Cheap!
        var to = Dates.lastDayOfMonth(moment(_this.to).subtract(1, 'month').toDate());
        var params = {
            from: Dates.firstDayOfMonth(to),
            to: to
        };

        if (_this.scoreId) {
            params.scoreId = _this.scoreId;
        }

        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        if (_this.filters) {
            params.filters = _this.filters;
        }


        if (_this.selectedFilters.groups && _this.selectedFilters.groups.length) {
            params.groupId = _this.selectedFilters.groups;
        }

        if (_this.selectedFilters.hierarchyIds && _this.selectedFilters.hierarchyIds.length) {
            params.hierarchyId = _this.selectedFilters.hierarchyIds.join(',');
        }

        if (_this.selectedFilters.segments && _this.selectedFilters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.selectedFilters.segments);
        }




        if (type === 'POSITIVE') {
            params.valuationTypes = ['EXCELLENT', 'GOOD'];
            params.fromInsight = _this.positiveInsights.length;
            params.toInsight = params.fromInsight + 3;
            params.sort = 'POSITIVE';
        } else {
            params.valuationTypes = ['BAD', 'WARNING'];
            params.fromInsight = _this.negativeInsights.length;
            params.toInsight = params.fromInsight + 3;
            params.sort = 'NEGATIVE';
        }

        InsightsService.getScoreInsights(params, function (err, result) {
            if (!err) {
                if (type === 'POSITIVE') {
                    if (loadMore) {
                        _this.positiveInsights = _.union(_this.positiveInsights, (processInsight(result.result, true)));
                    } else {
                        _this.positiveInsights = processInsight(result.result, true);
                    }
                    _this.totalPositive = result.total;
                } else {
                    if (loadMore) {
                        _this.negativeInsights = _.union(_this.negativeInsights, (processInsight(result.result, false)));
                    } else {
                        _this.negativeInsights = processInsight(result.result, false);
                    }
                    _this.totalNegative = result.total;
                }
            } else if (!next) {
                ErrorSvrc.showErrorModal(err);
            }

            if (next) {
                next(err, result);
            }
        });
    }

    function getInsights() {

        _this.positiveInsights = [];
        _this.negativeInsights = [];

        _this.loading = true;

        var streams = [];

        streams.push(function (next) {
            loadInsights(next, 'POSITIVE');
        });
        streams.push(function (next) {
            loadInsights(next, 'NEGATIVE');
        });

        async.parallel(streams, function (err, results) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }
            _this.loading = false;

        });

    }

    _this.loadMore = function (type) {
        loadInsights(null, type, true);
    };

    _this.onInsightClick = function (insight) {
        if (_this.onViewDetailClick) {
            _this.onViewDetailClick(insight.linkParams.scoreId, insight.linkParams.hierarchyId, insight.linkParams.filters );
        }
    }



    this.$onInit = function () {



        ['to', 'level', 'filters', 'selectedFilters'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n === o) {
                    return;
                }
                getInsights();
            }, true);
        });

        getInsights();

        getHierarchyAll(function (err, orgdata) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.hierarchyFiltersConfig.hierarchies = orgdata;
            }
        });

        Groups.getAll({ active: true }, function (err, groups) {
            if (err) {
                console.log('Error', err);
                return;
            }

            _this.groupFiltersConfig.groups = groups;
        });

        Characteristics.getAll(function (err, segments) {
            if (err) {
                console.log('Error', err);
                return;
            } 
            
            _this.segmentFiltersConfig.segments = segments;
        });
    };

}

const hfEngagementPrioritiesBox = angular.module('happyForceApp').directive('hfEngagementPrioritiesBox', function () {
    return {
        scope: {
            to: '<',
            dateGrouping: '<',
            scoreId: '<',
            level: '<',
            filters: '<',
            groups: '<',
            showFiltering: '<',
            onViewDetailClick: '<',
            onViewDetailLabel: '<',
        },
        restrict: 'E',
        template: require('./priorities_box.html'),
        controller: EngagementPrioritiesBoxCtrl,
        controllerAs: '$prioritiesbox',
        bindToController: true
    };
});

export default hfEngagementPrioritiesBox;
