import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { angularize, getService } from 'reactInAngular';
import {Tooltip} from '@mui/material';
import Dates from '../../../../utilities/date.utilities';
import Numbers from '../../../../utilities/number.utilities';
import {APISettings} from '../../../../shared/services/migrated/axiosWrapper';
import Valuation from '../../../../utilities/valuation.utils';
import ScaleChart from '../../../../shared/components/_migrated/scale-chart/scale-chart.component';
import Style from '../../../../utilities/style.utils';
import {getENPSStats} from '../../../../shared/services/migrated/enps.service';

import HappyLoading from '../../../../shared/components/_migrated/loader/loading.directive';
import EmptyState from '../../../../shared/components/_migrated/empty_state/empty_state';
import {dateToQueryParam} from '../../../../utilities/navigation_state.utilities';
import HFTag from 'root/app/shared/new-components/hf-tag/hf-tag';




const EngagementENPSBox = ({ to, dateGrouping }) => {
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false); // TODO: Remove this state and use enpsData.noData instead
    const [enps, setEnps] = useState(0);
    const [nextMeasurement, setNextMeasurement] = useState(null);
    const [enpsEnabled, setENPSEnabled] = useState(false); // TODO: Remove this state and use enpsData.enabled instead
    const [enpsColor, setEnpsColor] = useState(Style.neutral);
    const [variation, setVariation] = useState(0);
    const [variationText, setVariationText] = useState('');
    const [benchmarkDifferenceText, setBenchmarkDifferenceText] = useState('');
    const [quality, setQuality] = useState('GOOD');
    const [lastMeasurement, setLastMeasurement] = useState(null);
    const [averageResult, setAverageResult] = useState(0);
    const [tagStatus, setTagStatus] = useState('NEUTRAL');
    const [benchmarkDifference, setBenchmarkDifference] = useState(0);
    const [globalBenchmark, setGlobalBenchmark] = useState(0);
    const [hasEnoughActiveEmployees, setHasEnoughActiveEmployees] = useState(true);
    const $translate = getService('$translate');

    const ErrorSvrc = getService('ErrorSvrc');


    const slices =  [{
        color: Style.bad,
        range: [-100, -10],
        label: $translate.instant('ENPS_SLICE_FIRST')
    }, {
        color: Style.neutral,
        range: [-10, 20],
        label: $translate.instant('ENPS_SLICE_SECOND')
    }, {
        color: Style.warning,
        range: [20, 40],
        label: $translate.instant('ENPS_SLICE_THIRD')
    }, {
        color: Style.excellent,
        range: [40, 100],
        label: $translate.instant('ENPS_SLICE_FOURTH')
    }];

    useEffect(() => {
        setLoading(true);
        const params = {
            to: Dates.lastDayOfMonth(to),
            grouping: dateGrouping
        };

        getENPSStats(params, (err, result) => {
            setLoading(false);
            if (err) {
                if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                    setHasEnoughActiveEmployees(false);
                } else {
                    console.error('ERROR getting ENPS Stats', err);
                }
            } else {
                // Process and set data for ENPS
                if (result) {
                    setENPSEnabled(result.enabled);
                    if (!result.results || result.results.length === 0) {
                        setNoData(true);
                    } else {


                        setNoData(false);
                        setHasEnoughActiveEmployees(true);
                        const lastResult = result.results.slice(-1)[0];

                        setLastMeasurement(Dates.fromAPIFormat(lastResult.to, APISettings.apiDateFormat));

                        setNextMeasurement(result.nextMeasurement ? Dates.fromAPIFormat(result.nextMeasurement, APISettings.apiDateFormat) : null);
                        setQuality(lastResult.quality);

                        if (lastResult.quality === 'NO_DATA') {
                            setNoData(true);
                        } else {
                            setEnps( Numbers.roundNumber(lastResult.result));
                            setEnpsColor(Valuation.valuationColor(lastResult.valuation, true));
                            setVariation(lastResult.variation);
                            setQuality(lastResult.quality);
                            setAverageResult( Numbers.roundNumber(lastResult.averageResult,1));
                            setTagStatus(Valuation.enpsTagType(lastResult.result, lastResult.averageResult, lastResult.valuation, true));
                            setGlobalBenchmark(Numbers.roundNumber(lastResult.globalBenchmark, 1));
                            setBenchmarkDifference(Numbers.roundNumber(  Numbers.roundNumber(lastResult.result) - lastResult.globalBenchmark, 1));

                        }


                    }
                } else {
                    setNoData(true);
                }

            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [to, dateGrouping]);


    useEffect(() => {

        const keys = {
            previous: Numbers.roundNumber(enps - variation, 1),
            variation: Numbers.roundNumber(variation,1)
        };

        if (variation == 0) {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_NEUTRAL', keys));
        } else if (variation > 0) {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_POSITIVE', keys));
        } else {
            setVariationText($translate.instant('ENGAGEMENT_ENPS_COMPANY_COMPARISON_NEGATIVE', keys));
        }

    }, [enps, variation])


    useEffect(() => {

        const keys = {
            benchmark: Numbers.roundNumber(globalBenchmark,1),
            difference:  Numbers.roundNumber(benchmarkDifference,1)
        }

        if (benchmarkDifference == 0) {
            setBenchmarkDifferenceText($translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_NEUTRAL', keys));
        } else if (benchmarkDifference > 0) {
            setBenchmarkDifferenceText($translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_POSITIVE', keys));
        } else {
            setBenchmarkDifferenceText($translate.instant('ENGAGEMENT_ENPS_BENCHMARK_COMPARISON_NEGATIVE', keys));
        }

    }, [globalBenchmark, benchmarkDifference])

    const renderContent = () => {

        return (

            <HappyLoading loading={loading}>

                {!enpsEnabled && (
                    <div className="row m-t-xs">
                        <div className="col-xs-12">
                            <EmptyState
                                image="images/no_enps.svg"
                                small={true}
                                message={$translate.instant('ENGAGEMENT_ENPS_DISABLED')}
                            />
                        </div>
                    </div>
                )}

                {!hasEnoughActiveEmployees && (
                    <div className="row m-t-xs">
                        <div className="col-xs-12">
                            <EmptyState
                                image="images/anonymous.png"
                                small={true}
                                message={$translate.instant('NOT_ENOUGH_EMPLOYEES')}
                                submessage={$translate.instant('NOT_ENOUGH_EMPLOYEES_SUBMESSAGE')}
                            />
                        </div>
                    </div>
                )}

                {noData && (
                    <div className="row m-t-xs">
                        <div className="col-xs-12">
                            <div className="big-number-container" style={{ color: enpsColor }}>
                                <span className="big-number-no-data">{$translate.instant('NO_DATA')}</span>
                            </div>
                            <p className="small-style">{$translate.instant('ENPS_NO_DATA')}</p>
                        </div>
                    </div>
                )}

                {enpsEnabled && !noData && hasEnoughActiveEmployees && (
                    <div className="row same-height m-t-xs">
                        <div className="col-xs-12 col-sm-6">
                            <HFTag status={tagStatus}
                                text={$translate.instant('ENPS_AVERAGE_RESULT', {result: averageResult})}></HFTag>
                            <div className="big-number-container" style={{ color: enpsColor }}>
                                <span className="big-number">{enps}</span>
                                <span className="big-number-sub">pts</span>
                            </div>
                            <ScaleChart slices={slices} value={enps}></ScaleChart>
                        </div>
                        <div className="col-xs-12 col-sm-6 border-left space-evenly">
                            <p className="small-style" dangerouslySetInnerHTML={{ __html: variationText }}/>
                            <p className="small-style" dangerouslySetInnerHTML={{ __html: benchmarkDifferenceText }}/>
                        </div>
                    </div>
                )}


            </HappyLoading>
        )



    };

    return (
        <section className="enps_box white-bg">

            <div className="row">
                <div className="col-xs-12 col-sm-9">
                    <h2 className="big-style">{$translate.instant('ENPS_TITLE')}</h2>
                    <span>
                        {!loading && lastMeasurement && (
                            <span>
                                <p className="mini-style warm-grey m-t-xs">
                                    {$translate.instant('ENGAGEMENT_QUARTER_MEASUREMENT_OF', {
                                        date: moment(lastMeasurement).format('MMM YYYY'),
                                        quarter: moment(lastMeasurement).format('[Q]Q'),
                                    })}

                                    <Tooltip title={$translate.instant('ENGAGEMENT_QUARTER_NEXT_MEASUREMENT', {
                                        date: moment(nextMeasurement).format('MMM YYYY'),
                                    })}>
                                        <i className="fa fa-info-circle text-success m-l-xs"></i>
                                    </Tooltip>

                                </p>

                                {['GOOD', 'NORMAL', 'WARNING'].includes(quality) && (
                                    <Tooltip title={$translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION')}>
                                        <p
                                            className="mini-style underline"
                                            dangerouslySetInnerHTML={{__html: $translate.instant('REPRESENTATIVE_SAMPLE')}}
                                        />
                                    </Tooltip>
                                )}
                                {quality === 'LOW' && (
                                    <Tooltip title={$translate.instant('SCORE_LOW_QUALITY_DESCRIPTION')}>
                                        <p
                                            className="mini-style underline"
                                            dangerouslySetInnerHTML={{__html: $translate.instant('NO_REPRESENTATIVE_SAMPLE')}}
                                        />

                                    </Tooltip>
                                )}
                            </span>
                        )}
                    </span>
                </div>
                <div className="col-xs-12 col-sm-3 text-right">
                    {!loading && lastMeasurement && (
                        <a href={`#!/engagement/detail/enps?to=${dateToQueryParam(to)}`} className="link-style">
                            {$translate.instant('ENGAGEMENT_VIEW_DETAIL')}
                        </a>
                    )}
                </div>
            </div>
            {renderContent()}



        </section>
    );
};

EngagementENPSBox.propTypes = {
    to: PropTypes.any.isRequired,
    dateGrouping: PropTypes.string.isRequired,
};

angularize(EngagementENPSBox, 'hfEngagementEnpsBox', angular.module('happyForceApp'), {
    to: '<',
    dateGrouping: '<'
});

export default EngagementENPSBox;
