import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, Box, ListItemText, Typography } from '@mui/material';
import { Group } from '../types';

interface GroupNestedMenuItemProps {
    open?: boolean;
    groups: Group[];
    filters: string[];
    setGroupFilters: (filters: string[]) => void;
    title: string;
    mode: string;
    nonRemovable: boolean;
}

const GroupNestedMenuItem = ({ open, groups, filters, setGroupFilters, title, mode, nonRemovable }: GroupNestedMenuItemProps) => {
    //handle with remove/add
    const handleFilter = (item) => {
        if (mode === 'multi') {
            if(filters && filters.includes(item.id)) {
                if(!nonRemovable) {
                    setGroupFilters(filters.filter(filterItem => filterItem !== item.id));
                }
            } else {
                setGroupFilters([...filters, item.id]);
            }
        } else if (mode === 'single') { 
            if(filters && filters.includes(item.id)) {
                if(!nonRemovable) {
                    setGroupFilters(filters.filter(filterItem => filterItem !== item.id));
                }
            } else {
                setGroupFilters([item.id]);
            }
        } else {
            // TODO: grouped
        }
    }

    const renderGroups = (items) => {
        if (!items) {
            return null;
        }

        if (mode === 'multi') {
            return items.map((item, index) => {
                const isChecked = filters && filters.includes(item.id);

                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(item)}>
                            <Checkbox
                                checked={isChecked} /><ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return items.map((item, index) => {
                const isSelected = filters && filters.includes(item.id);
                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleFilter(item)}
                            renderLabel={() => <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Radio
                                    checked={isSelected}
                                />
                                <ListItemText primary={
                                    <Typography style={{ fontSize: '14px' }}>
                                        {item.name}
                                    </Typography>
                                } />
                            </Box>}
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderGroups(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            sx={{ paddingLeft: 1 }}
                            key={index}
                            onClick={() => handleFilter(item)}
                        >
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderGroups(groups)}
        </NestedMenuItem>
    );
};

export default GroupNestedMenuItem;
